<template>
  <div class="ls-create__wrapper">
    <LxpLoader v-if="isLoading" />
    <ValidationObserver
      ref="create-space-observer"
      @submit.prevent="submitForm()"
      v-else
      v-slot="{ failed, dirty }"
    >
      <div class="ls-create__form ls-create__name">
        <div>
          <LxpDragDrop
            dragDropClass="ls-create__upload"
            :heightDimension="144"
            :accept="['jpeg', 'jpg', 'png']"
            validateFileDimension
            variant="variant2"
            :authToken="authToken"
            :fileUploadUrl="getUploadUrl()"
            :errorMessage="errorMessage"
            @file-url="onLogoChange"
            @remove-file="onRemoveLogo()"
            id="create-logo"
            v-model="createSpace.logo"
          >
            <template v-slot:content>
              <label
                for="lxp-file-input-create-logo"
                class="ls-create__upload--label"
              >
                <div class="ls-create__name--upload">
                  <img
                    src="@/assets/images/learning-space/upload.svg"
                    alt="upload"
                  />
                  <p>Upload Logo*</p>
                </div>
                <div class="ls-create__name--content">
                  <span>Specs: 144 pixels of height.</span>
                  <span> File format: jpeg, jpg or png.</span>
                  <span> Max. size: 1MB</span>
                </div>
              </label>
            </template>
          </LxpDragDrop>
          <span
            class="ls-create__error-text"
            v-if="(failed || dirty) && !createSpace.logo"
            >The logo is required</span
          >
        </div>
        <div class="ls-create__name--input">
          <ValidationProvider
            name="Space name"
            rules="required"
            v-slot="{ errors }"
          >
            <LxpInput
              label="Space name*"
              :maxlength="250"
              showLimit
              v-model="createSpace.name"
              :hasError="errors[0] ? true : false"
              :supportText="errors[0] ? errors[0] : ''"
            />
          </ValidationProvider>
        </div>
      </div>
      <ValidationProvider
        name="Short headline"
        rules="required"
        v-slot="{ errors }"
      >
        <div class="ls-create__form">
          <LxpInput
            label="Short headline*"
            :maxlength="250"
            showLimit
            v-model="createSpace.about.short_headline"
            :hasError="errors[0] ? true : false"
            :supportText="errors[0] ? errors[0] : ''"
          />
        </div>
      </ValidationProvider>
      <ValidationProvider name="Info" rules="required" v-slot="{ errors }">
        <div class="ls-create__form">
          <LxpTextarea
            label="Info*"
            placeholder="Please provide a brief description of this entity, including its mission, services, and any other relevant information. Keep it professional and avoid sharing sensitive information."
            :limit="10000"
            :maxRows="12"
            :rows="5"
            showLimit
            v-model="createSpace.about.info"
            :hasError="errors[0] ? true : false"
            :supportText="errors[0] ? errors[0] : ''"
          />
        </div>
      </ValidationProvider>
      <div class="ls-create__form">
        <LxpDragDrop
          noteText="Image should not exceed 1Mb. File format jpeg, jpg or png. Recommended size 960*640px"
          dragDropClass="ls-create__image"
          :dropIcon="
            require('@/assets/images/learning-space/drag-drop-image.svg')
          "
          :accept="['jpeg', 'jpg', 'png']"
          :authToken="authToken"
          :fileUploadUrl="getUploadUrl()"
          @file-url="onCoverChange"
          v-model="createSpace.about.image"
          @remove-file="onRemoveCover()"
        />
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import { LxpInput, LxpTextarea, LxpDragDrop, LxpLoader } from "didactica";
import { ValidationObserver } from "vee-validate";
import { ValidationProvider } from "vee-validate/dist/vee-validate.full.esm";
import { mapGetters } from "vuex";

export default {
  components: {
    LxpInput,
    LxpLoader,
    LxpTextarea,
    LxpDragDrop,
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      createSpace: {
        logo: "",
        name: "",
        org: "",
        about: {
          short_headline: "",
          info: "",
          image: ""
        }
      },
      errorMessage: {
        SIZE: "Image size exceeds 1MB.",
        DIMENSION: "Image height exceeds 144 pixels.",
        UPLOAD: "Error uploading image."
      },
      authToken: "",
      isLoading: false
    };
  },
  computed: {
    ...mapGetters(["allConfig", "getOrgList"])
  },
  created() {
    if (this.getOrgList.length === 0) {
      this.listOrganisation();
    }
    this.authToken = `Bearer ${this.$keycloak.token}`;
  },
  methods: {
    listOrganisation() {
      this.isLoading = true;
      this.$store.dispatch("listAllOrganisation").then(() => {
        this.isLoading = false;
      });
    },
    onLogoChange(e) {
      this.createSpace.logo = e.image;
    },
    onCoverChange(e) {
      this.createSpace.about.image = e.image;
    },
    onRemoveLogo() {
      this.createSpace.logo = "";
    },
    onRemoveCover() {
      this.createSpace.about.image = "";
    },
    async validate() {
      return await this.$refs["create-space-observer"].validate();
    },
    submitForm() {
      this.createSpace.org = this.getOrgList[0].org_short_name;
      if (this.createSpace.logo !== "") {
        this.$store.dispatch("createSpace", this.createSpace);
      }
    },
    getUploadUrl() {
      return `${this.allConfig.baseURL}/spaces/upload-image/`;
    }
  }
};
</script>

<style lang="scss">
@import "@/scss/_learningspace.scss";
.ls-create__wrapper {
  .ls-create__name {
    .ls-create__name--input {
      margin-left: 16px;
    }
  }
  .lxp-dd__error--variant {
    padding: 0px !important;
  }
}
</style>
