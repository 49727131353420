<template>
  <div class="learning-spaces-wrapper">
    <LxpLoader v-if="isLoading" />
    <div v-else>
      <create-space v-if="!hasLearningSpace" />
      <SpacesList v-else />
    </div>
  </div>
</template>

<script>
import { LxpLoader } from "didactica";
import SpacesList from "../LearningSpace/SpacesList.vue";
import CreateSpace from "./LearningSpace/Create.vue";
// mixins
import fetchSpaceList from "./mixins/fetchSpaceList";
export default {
  provide: {
    parentRoute: "gateway"
  },
  mixins: [fetchSpaceList],
  components: {
    LxpLoader,
    CreateSpace,
    SpacesList
  },
  computed: {
    hasLearningSpace() {
      return this.getSpacesList.length > 0;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.isLoading = true;
      this.$store
        .dispatch("getSpacesList")
        .then(() => {
          this.isLoading = false;
        })
        .catch(() => {
          this.$router.push({
            name: "UnauthorisedAccess"
          });
        });
    }
  }
};
</script>
