import { mapGetters } from "vuex";
export default {
  data() {
    return {
      isLoading: false
    };
  },
  computed: {
    ...mapGetters(["getSpacesList"])
  },
  methods: {
    init() {
      this.isLoading = true;
      this.$store
        .dispatch("getSpacesList")
        .then(() => {
          this.isLoading = false;
        })
        .catch(() => {
          this.$router.push({
            name: "UnauthorisedAccess"
          });
        });
    }
  }
};
