<template>
  <div class="lsw-create__wrapper">
    <div class="lsw__create" v-if="!showCreateForm">
      <div class="lsw__create--left">
        <h6>Welcome to Learning spaces</h6>
        <h4>
          Enhance your visibility on the learning platform. Enable learners to
          acquaint themselves with both you and your educational content.
        </h4>
        <p>
          By providing the opportunity for students to become better acquainted
          with both you as an instructor and your course content, you can
          establish a more robust connection and foster a deeper understanding
          of your subject matter.
        </p>
        <LxpButton variant="secondary1" @click="showCreateForm = true"
          ><img
            src="@/assets/images/partner/learning-space/learning-space-icon.svg"
            alt="icon"
          />Create Learning space</LxpButton
        >
      </div>
      <div class="lsw__create--right">
        <img
          src="@/assets/images/partner/learning-space/placeholder.png"
          alt="placeholder"
        />
      </div>
    </div>
    <div class="lsw__create--form" v-else>
      <div class="lsw__create--form__heading">
        <h5>Creating learning space</h5>
        <LxpButton variant="secondary1" @click="onCreate">Save</LxpButton>
      </div>
      <div class="lsw__create--form__main">
        <CreateLearningSpace ref="createPartnerSpace" />
        <ContentRight />
      </div>
    </div>
  </div>
</template>

<script>
import { LxpButton } from "didactica";
import CreateLearningSpace from "@/components/LearningSpace/CreateLearningSpace.vue";
import ContentRight from "@/components/Layout/UserLayout/ContentRight.vue";
export default {
  components: {
    LxpButton,
    CreateLearningSpace,
    ContentRight
  },
  data() {
    return {
      showCreateForm: false
    };
  },
  methods: {
    onCreate() {
      this.$refs["createPartnerSpace"].validate().then(res => {
        if (res) {
          this.$refs["createPartnerSpace"].submitForm();
        }
      });
    }
  }
};
</script>

<style lang="scss">
.lsw-create__wrapper {
  .lsw__create {
    border-radius: 16px;
    background: $brand-neutral-0;
    padding: 16px;
    @include flex-horizontal-center;
    .lsw__create--left {
      h6 {
        @include body-regular(400, $brand-neutral-900);
        margin-bottom: 16px;
      }
      h4 {
        @include h4;
        margin-bottom: 16px;
      }
      p {
        @include body-medium;
        color: $brand-neutral-300;
        margin-bottom: 24px;
      }
      img {
        margin-right: 8px;
      }
    }
    .lsw__create--right {
      margin-left: 8%;
    }
  }
  .lsw__create--form {
    background: $brand-neutral-0;
    box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);
    padding: 16px;
    border-radius: 16px;
    .lsw__create--form__heading {
      @include horizontal-space-between;
      margin-bottom: 32px;
    }
    .lsw__create--form__main {
      display: flex;
      justify-content: space-between;
      .ls-create__wrapper {
        margin-right: 24px;
        background: transparent;
        box-shadow: none;
        padding: 0;
        width: 80%;
      }
      .usl-right__content {
        width: 20%;
      }
    }
  }
}
</style>
