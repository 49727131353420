<template>
  <div class="learning-space__list">
    <label>All Learning Spaces</label>
    <LearningSpaceCard
      v-for="(space, i) in spacesList"
      :key="i"
      :logo="space.logo"
      :title="space.title"
      :subTitle="space.subTitle"
      :org="space.org"
      :onManageClick="() => onManageClick(space.org)"
      :publishStatus="space.publishStatus"
    ></LearningSpaceCard>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LearningSpaceCard from "./Cards/LearningSpaceCard.vue";
export default {
  inject: ["parentRoute"],
  components: {
    LearningSpaceCard
  },
  computed: {
    ...mapGetters(["getSpacesList"]),
    spacesList() {
      let spacesList = [];
      this.getSpacesList.forEach(space => {
        spacesList.push({
          logo: space.logo
            ? space.logo
            : require("@/assets/images/layout/header/whoa-logo.svg"),
          title: space.name,
          org: space.org_short_name,
          subTitle: space.about?.short_headline,
          publishStatus: space.is_published
        });
      });
      return spacesList;
    }
  },
  methods: {
    onManageClick(org) {
      let name =
        this.parentRoute === "gateway"
          ? "EditPartnerLearningSpace"
          : "SelectedLearningSpace";

      this.$router.push({
        name: name,
        params: { org: org }
      });
    }
  }
};
</script>

<style lang="scss">
.learning-space__list {
  label {
    @include body-regular(500);
    margin-bottom: 24px;
  }
}
</style>
